$(function() {
  const form = $('#cc-form')
  if (!(form.length > 0)) {
    return
  }

  const elements = stripe.elements({
    fonts: [
      {
        family: 'Roboto',
        src:
          'url(https://dtrdx51xphawd.cloudfront.net/assets/roboto-v15-latin-regular-e3dd9a15e7caea392fce4ed32c89db8c807e41234892c5e8b100d7c49c4e0a6a.woff2)',
        weight: 400
      }
    ]
  })

  const card = elements.create('card', {
    hidePostalCode: true,
    style: {
      base: {
        fontFamily:
          "Roboto, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Arial, Helvetica, sans-serif",
        fontSize: '15px',
        lineHeight: '23px'
      }
    }
  })
  card.mount('#card-element')

  const nameElem = $('#cc-name')
  const errorsElem = $('#cc-errors')
  const loaderElem = $('.cc-form__loader')
  const tokenElem = $('#cc-stripe-token')
  const buttonElem = $('#cc-submit, #cc-close')

  const setOutcome = function(result) {
    if (result.error) {
      errorsElem.text(result.error.message)
      buttonElem.attr('disabled', false);
      loaderElem.removeClass('cc-form__loader--active')
    } else {
      errorsElem.text('')

      if (result.token) {
        tokenElem.val(result.token.id)
        form.submit()
      }
    }
  }

  card.addEventListener('change', setOutcome)

  form.submit(function(evt) {
    buttonElem.attr('disabled', true);
    loaderElem.addClass('cc-form__loader--active')
    if (!tokenElem.val()) {
      evt.preventDefault()
      stripe.createToken(card, { name: nameElem.val() }).then(setOutcome)
    }
  })
})
